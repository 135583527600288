import React from 'react';
import { graphql } from 'gatsby';
import MarkdownIt from 'markdown-it';

import Layout, { Content } from './components/Layout';
import HeroHeader from './components/heroheader';
import ContentNavigation from './components/Navigation/Content';
import createPersistedState from 'use-persisted-state';
import { Currencies } from './components/config/currency';

const useCurrency = createPersistedState('currency');

const CURRENCY_SYMBOLS = {};

Currencies.supported.forEach(({ code, symbol }) => {
  CURRENCY_SYMBOLS[code] = symbol;
});

export const query = graphql`
  query ProductQuery($id: Int!) {
    strapiProducts(strapiId: { eq: $id }) {
      Content
      Title
      Cost {
        EUR
        GBP
        USD
        NZD
        AUD
      }
      Key_Visual {
        publicURL
      }
    }
  }
`;

const Programme = ({ data, pageContext, location }) => {
  const product = data.strapiProducts;
  const [activeCurrency] = useCurrency(Currencies.default);

  const content = product.Content;
  const keyVisualUrl = product.Key_Visual ? product.Key_Visual.publicURL : '';
  const cost = product.Cost;
  const navigation = pageContext.navigation;

  const md = new MarkdownIt({ html: true });
  const contentHtml = content ? md.render(content) : '';

  return (
    <Layout title={product.Title}>
      <HeroHeader
        title={product.Title}
        cta={product.CTA}
        backgroundUrl={keyVisualUrl}
        infoData={{
          cost: `${CURRENCY_SYMBOLS[activeCurrency]} ${
            cost ? cost[activeCurrency] : '-'
          }`,
        }}
      />
      <ContentNavigation
        links={navigation}
        currentPathName={location.pathname}
      />
      <Content dangerouslySetInnerHTML={{ __html: contentHtml }} />
    </Layout>
  );
};

export default Programme;
